import { get, post, edit, del, patch } from './api';
import { withSentry } from '@sentry/nextjs';

class APIServices {
  url;
  name;
  data;
  success = false;

  constructor(url) {
    this.url = url;
  }

  getURL(id) {
    return Boolean(id) ? `/${this.url}/${id}` : `/${this.url}`;
  }

  async get() {
    await get({ url: this.url })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        // console.log("Error APiService");
      });
    return { data: this.data, success: this.success };
  }

  async post(formData) {
    await post({ url: this.url, data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {
        this.data = e.response?.data;
      });
    return { data: this.data, success: this.success };
  }

  async edit(id, formData) {
    await edit({ url: this.getURL(id), data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {});
    return { data: this.data, success: this.success };
  }

  async patch(id, formData) {
    await patch({ url: this.getURL(id), data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.success = true;
          this.data = response.data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {});
    return { data: this.data, success: this.success };
  }

  async delete(id) {
    await del({ url: this.getURL(id) })
      .then((response) => {
        if (response.status < 300) {
          this.success = true;
          this.data = response.data;
        } else {
          return Promise.reject(response.data);
        }
      })
      .catch((e) => {});
    return { data: this.data, success: this.success };
  }
}

export default (APIServices);
